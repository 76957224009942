<template>
  <v-card elevation="0" max-width="1010" rounded="xl" class="mx-auto pa-sm-8 pa-4 personal width100">
    <v-btn class="btn-back px-2" text @click="back">
      <v-icon small color="gray" class="pt-1">
        {{ $i18n.locale == 'en' ? 'mdi-chevron-left' : 'mdi-chevron-right' }} mdi-24px
      </v-icon>
      <span class="gray--text text-body-1">{{ $t('btn.back') }}</span>
    </v-btn>
    <v-img height="105" contain class="mx-auto mb-12" src="@/assets/icon/logo.svg" alt="logo"></v-img>
    <div class="f30 arabic-black text-center mb-2 black--text">{{ $t('infoPage.description') }}</div>
    <div class="gray--text text-center mb-13">{{ $t('infoPage.description') }}</div>
    <div style="max-width: 800px" class="mx-auto">
      <v-form @submit.prevent="action" ref="form" lazy-validation>
        <div class="d-flex flex-sm-nowrap flex-wrap justify-center personal_fields">
          <div>
            <div class="black--text">{{ $t('input.firstName') }}</div>
            <v-text-field
              v-model="data.first_name"
              :error-messages="firstNameErrors"
              :placeholder="$t('input.firstName')"
              outlined
              required
              color="secondary"
            ></v-text-field>
          </div>
          <div>
            <div class="black--text">{{ $t('input.fathersName') }}</div>
            <v-text-field
              v-model="data.father_name"
              :error-messages="fatherNameErrors"
              :placeholder="$t('input.fathersName')"
              outlined
              required
              color="secondary"
            ></v-text-field>
          </div>
        </div>
        <div class="d-flex flex-sm-nowrap flex-wrap justify-center personal_fields">
          <div>
            <div class="black--text">{{ $t('input.grandfathersName') }}</div>
            <v-text-field
              v-model="data.grandfather_name"
              :error-messages="grandfatherNameErrors"
              :placeholder="$t('input.grandfathersName')"
              outlined
              required
              color="secondary"
            ></v-text-field>
          </div>
          <div>
            <div class="black--text">{{ $t('input.familyName') }}</div>
            <v-text-field
              v-model="data.family_name"
              :error-messages="familyNameErrors"
              :placeholder="$t('input.familyName')"
              outlined
              required
              color="secondary"
            ></v-text-field>
          </div>
        </div>
        <div class="text-center mt-4">
          <v-btn height="64" depressed type="submit" max-width="380" width="100%" large class="primary radius10">
            <span class="text-body-1 font-weight-bold">{{ $t('btn.confirm') }}</span>
          </v-btn>
        </div>
      </v-form>
    </div>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  validations: {
    data: {
      first_name: {
        required,
      },
      father_name: {
        required,
      },
      family_name: {
        required,
      },
    },
  },
  data() {
    return {
      data: {},
      error: [],
    };
  },
  async mounted() {
    await this.$store.dispatch("getProfile");
  },
  methods: {
    notifi(btn) {
      if (btn == 500) {
        this.$notify({
          title: this.$t('alert.error500Title'),
          message: this.$t('alert.error500'),
          type: 'error',
          icon: 'mdi-alert',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    back() {
      this.$router.go(-1);
    },
    async action() {
      this.error = [];
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.$store
          .dispatch("updateProfile", this.data)
          .then(() => {
            this.$router.push("/");
          })
          .catch((e) => {
            this.error = e.response.data.error;
            if (e.response.status >= 500) {
              this.notifi(500);
            }
          });
      }
    },
  },
  watch: {
    profile(val) {
      this.data = {
        first_name: val.first_name_en,
        father_name: val.father_name_en,
        grandfather_name: val.grandfather_name_en,
        family_name: val.family_name_en,
      }
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    firstNameErrors() {
      const errors = [];
      if (!this.$v.data.first_name.$dirty) {
        return errors;
      }
      !this.$v.data.first_name.required && errors.push(this.$t('inputError.firstNameRequired'));
      this.error.find((item) => item == 'first_name__required') && errors.push(this.$t('inputError.firstNameRequired'));
      this.error.find((item) => item == 'first_name__invalid') && errors.push(this.$t('inputError.firstNameInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
    fatherNameErrors() {
      const errors = [];
      if (!this.$v.data.father_name.$dirty) {
        return errors;
      }
      !this.$v.data.father_name.required && errors.push(this.$t('inputError.fatherNameRequired'));
      this.error.find((item) => item == 'father_name__required') && errors.push(this.$t('inputError.fatherNameRequired'));
      this.error.find((item) => item == 'father_name__invalid') && errors.push(this.$t('inputError.fatherNameInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
    grandfatherNameErrors() {
      const errors = [];
      this.error.find((item) => item == 'grandfather_name__required') && errors.push(this.$t('inputError.grandfatherNameRequired'));
      this.error.find((item) => item == 'grandfather_name__invalid') && errors.push(this.$t('inputError.grandfatherNameInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
    familyNameErrors() {
      const errors = [];
      if (!this.$v.data.family_name.$dirty) {
        return errors;
      }
      !this.$v.data.family_name.required && errors.push(this.$t('inputError.familyNameRequired'));
      this.error.find((item) => item == 'family_name__required') && errors.push(this.$t('inputError.familyNameRequired'));
      this.error.find((item) => item == 'family_name__invalid') && errors.push(this.$t('inputError.familyNameInvalid'));
      this.error.find((item) => item == 'bad_credentials') && errors.push(this.$t(''));
      return errors;
    },
  },
};
</script>

<style lang="scss">
.personal {
  &_fields {
    gap: 0 40px;
  }
  &_fields > div {
    max-width: 380px;
    width: 100%;
  }
}
</style>